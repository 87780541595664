import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { motion } from 'framer-motion';

import { RMIconButton } from '@/components/RMIconButton/RMIconButton';
import { RMText } from '@/components/RMText/RMText';

export const StoryPlayerWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
`;

export const ContentRoot = styled.div`
  display: flex;
  align-items: center;
  overflow: visible;
  width: 100%;
  height: 100%;
  padding: var(--spacing-xl) var(--spacing-6xl);

  body[data-tablet='true'] &,
  body[data-mobile='true'] & {
    padding: 0;
  }
`;

export const ContentMain = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 93.75rem;
  margin: 0 auto;
  box-shadow: var(--elevation-bottom-4);

  body[data-fullscreen='true'] & {
    max-width: unset;
  }
`;

export const playerContextStyles = css`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

export const CloseButton = styled.div`
  position: absolute;
  top: var(--spacing-xl);
  right: var(--spacing-xl);
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  body[data-fullscreen='true'] & {
    display: none;
  }
`;

export const MobileCloseButton = styled(RMIconButton)`
  position: absolute;
  top: var(--spacing-md);
  left: var(--spacing-md);
  background-color: var(--surface);
  z-index: 10;

  body[data-fullscreen='true'] & {
    display: none;
  }
`;

export const Dialog = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100dvh;
  outline: none;
  padding: 0;
  overflow: hidden;
  background-color: transparent;
  z-index: 999;
`;

export const Container = styled(motion.div)`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;

  > div {
    position: relative;
    height: 100%;
    width: 100%;
  }
`;

export const BackgroundBackdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--color-black-0-90);
`;

export const BackgroundImage = styled(motion.img)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(1rem);
  scale: 1.2;
  opacity: 0.1;
`;

export const Foreground = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
`;

export const PrevButton = styled(RMIconButton)`
  position: fixed;
  left: var(--spacing-xl);
  top: 50%;
  transform: translateY(-50%);
`;

export const NextButton = styled(RMIconButton)`
  position: fixed;
  right: var(--spacing-xl);
  top: 50%;
  transform: translateY(-50%);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: var(--spacing-xl) var(--spacing-2xl) 0 var(--spacing-2xl);

  body[data-mobile='true'] & {
    padding: var(--spacing-lg) var(--spacing-md);
  }
`;

export const HeaderWordmark = styled.img`
  height: 1.5rem;

  body[data-mobile='true'] & {
    height: 1rem;
  }
`;

export const HeaderLink = styled(RMText)`
  cursor: pointer;
`;
