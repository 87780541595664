import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ACL_VIEW_ROLES, AclGroupRole } from '@remento/types/acl';
import { AssetAlternativeMetadataType, RecordingAssetAlternativeType } from '@remento/types/alternative';
import { StoryDataType, StoryLengthType, StoryPerspectiveType } from '@remento/types/story';
import { getStorySummary, getStoryTitle } from '@remento/utils/entity/story';
import { MediaPauseEvent, MediaPlayEvent, MediaTimeUpdateEventDetail, PlayerSrc } from '@vidstack/react';

import { stringToRMTextEditorContent } from '@/components/RMTextEditor/RMTextEditor';
import { createRMTextEditorStore } from '@/components/RMTextEditor/RMTextEditor.state';
import { isFullScreen } from '@/hooks/useIsFullscreen';
import { isMobileViewport } from '@/hooks/useIsMobileViewport';
import { logger } from '@/logger';
import { isFormDirty, subscribeIsFormDirty } from '@/modules/form/form';
import { PlayerContext } from '@/modules/media-player/PlayerContext';
import { useFullscreenStore } from '@/modules/media-player/states';
import { getHighlightReelPath } from '@/modules/routing';
import { StoryShareDialogContainer } from '@/modules/sharing/containers/StoryShareDialog.container';
import { useServices } from '@/Services';
import { hasRole, useCurrentUserAclRoles } from '@/services/api/acl';
import { useAlternativeType, useAssetAlternativesQuery } from '@/services/api/asset';
import { useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';
import { useProjectQuery, usePromptFirstQuestionQuery } from '@/services/api/project';
import { useReactionsQuery, useSubmittedReactions } from '@/services/api/reaction';
import { useRecordingQuery } from '@/services/api/recording';
import { useStoryQuery } from '@/services/api/story';

import { StoryView } from '../components/StoryView/StoryView';
import { createStoryRegenerateForm } from '../forms/story-regenerate.form';
import { createStorySummaryForm } from '../forms/story-summary.form';
import { useStoryVideoUrl } from '../hooks/useStoryVideo';
import { StoriesManager, useNextStoryId, usePreviousStoryId } from '../states/stories.manager';
import {
  createStoryManager,
  getStoryState,
  observeStoryState,
  setStoryState,
  StoryState,
  useStoryState,
} from '../states/story.manager';

import { StoryAnonymousReactionDialogContainer } from './StoryAnonymousReactionDialog.container';
import { StoryPlayerContainer } from './StoryPlayer.container';
import { StoryReactionDialogContainer } from './StoryReactionDialog.container';

const REACT_ROLES = [AclGroupRole.OWNER, AclGroupRole.ADMIN, AclGroupRole.COLLABORATOR];
interface StoryViewContainerProps {
  storyId: string;
  storiesManager: StoriesManager;
  activeStory: boolean;
  showClose?: boolean;
  animationDir: 'ltr' | 'rtl' | null;
  onFormDirtyChange: (isDirty: boolean) => void;
  onChangeStoryState: (state: StoryState) => void;
  onClose: () => void;
}

export function StoryViewContainer({
  storyId,
  storiesManager,
  activeStory,
  animationDir,
  onFormDirtyChange,
  onChangeStoryState,
  onClose,
}: StoryViewContainerProps) {
  // Services
  const { storyViewerAnalyticsService } = useServices();

  const [searchParams] = useSearchParams();
  const storyManager = useMemo(() => createStoryManager(), []);
  const fullscreenStore = useFullscreenStore();

  const storyQuery = useStoryQuery(storyId);
  const story = storyQuery.data;
  const recordingId = story?.recordingsIds[0] || '';

  const projectQuery = useProjectQuery(story?.projectId);
  const project = projectQuery.data;

  const recordingQuery = useRecordingQuery(story?.recordingsIds[0]);
  const user = useUser();
  const personQuery = usePersonQuery(user?.personId);
  const userHasRecorded = personQuery.data?.refIds.includes(recordingQuery.data?.personId ?? '');

  // Permissions
  const userStoryRoles = useCurrentUserAclRoles(story?.acl ?? null);
  const canReact = hasRole(REACT_ROLES, userStoryRoles ?? []) && !userHasRecorded;
  const userProjectRoles = useCurrentUserAclRoles(projectQuery.data?.acl ?? null);
  const canViewProject = hasRole(ACL_VIEW_ROLES, userProjectRoles ?? []);

  const nextStoryId = useNextStoryId(storiesManager);
  const previousStoryId = usePreviousStoryId(storiesManager);

  // Do not fetch the reactions if the user does not have permission.
  const reactionsQuery = useReactionsQuery(canReact ? recordingId : null);
  const submittedReactionsIds = useSubmittedReactions(reactionsQuery.data ?? null);

  const promptQuestionQuery = usePromptFirstQuestionQuery(story?.promptId ?? null);
  const promptQuestion = promptQuestionQuery?.data;

  const storyState = useStoryState(storyManager);
  const storiesType = storiesManager.type;

  // Player Context
  const title = getStoryTitle(storyQuery.data) ?? storyQuery.data?.metadata.title ?? '';
  const recordingUrl = useStoryVideoUrl(storyId) || '';
  const src = useMemo<PlayerSrc>(() => ({ src: recordingUrl, type: 'application/x-mpegurl' }), [recordingUrl]);

  // Highlight Reels
  const reelAlternativesQuery = useAssetAlternativesQuery(storyQuery.data?.recordingsIds[0]);
  const reelAlternative = useAlternativeType(
    reelAlternativesQuery.data,
    RecordingAssetAlternativeType.HIGHLIGHT_REEL,
    AssetAlternativeMetadataType.VIDEO,
  );

  // Analytics
  const [isPlaying, setIsPlaying] = useState(false);
  const videoStartTime = useRef<number | null>(null);
  const videoWatchedTotalDuration = useRef<number>(0);
  const videoCurrentTime = useRef<number>(0);

  const storyPerspective =
    story?.summary?.type !== StoryDataType.TRANSCRIPT_TOO_SHORT
      ? (story?.summary?.settings?.perspective as StoryPerspectiveType)
      : undefined;
  const storyLength =
    story?.summary?.type !== StoryDataType.TRANSCRIPT_TOO_SHORT
      ? (story?.summary?.settings?.length as StoryLengthType)
      : undefined;

  const titleTextEditor = useMemo(
    () => createRMTextEditorStore(stringToRMTextEditorContent(getStoryTitle(story))),
    [story],
  );
  const summaryTextEditor = useMemo(
    () => createRMTextEditorStore(stringToRMTextEditorContent(getStorySummary(story))),
    [story],
  );
  const summaryForm = useMemo(
    () =>
      createStorySummaryForm({
        title: '',
        summary: '',
        perspective: storyPerspective,
        length: storyLength,
      }),
    [storyPerspective, storyLength],
  );

  const regenerateForm = useMemo(
    () =>
      createStoryRegenerateForm({
        perspective:
          storyPerspective ?? project?.configuration.defaultStoryPerspective ?? StoryPerspectiveType.THIRD_PERSON,
        length: storyLength ?? StoryLengthType.MEDIUM,
      }),
    [storyPerspective, storyLength, project],
  );

  useEffect(() => {
    // Every time the form changes, trigger the callback immediately to make sure that the value is up to date.
    onFormDirtyChange(isFormDirty(summaryForm));
    return subscribeIsFormDirty(summaryForm, (isDirty) => onFormDirtyChange(isDirty === true));
  }, [summaryForm, onFormDirtyChange]);

  useEffect(() => {
    videoCurrentTime.current = 0;
    videoStartTime.current = null;
    videoWatchedTotalDuration.current = 0;
  }, []);

  const handlePlay = useCallback((event: MediaPlayEvent) => {
    videoStartTime.current = event.target.currentTime;
    setIsPlaying(true);
  }, []);

  const handleTimeUpdate = useCallback((event: MediaTimeUpdateEventDetail) => {
    videoCurrentTime.current = event.currentTime;
  }, []);

  const handlePause = useCallback(
    (event: MediaPauseEvent) => {
      if (videoStartTime.current === null) {
        throw new Error('The video start time is not available');
      }

      videoWatchedTotalDuration.current += event.target.currentTime - videoStartTime.current;

      // Make sure the duration is rounded to the nearest 1/100
      const duration = Math.round(videoWatchedTotalDuration.current * 100) / 100;
      storyViewerAnalyticsService.onStoryWatched(duration, 'original');

      videoCurrentTime.current = 0;
      videoWatchedTotalDuration.current = 0;
      videoStartTime.current = null;
      setIsPlaying(false);
    },
    [storyViewerAnalyticsService],
  );

  const handleEnded = useCallback(async () => {
    if (storyState.type !== 'view' || canReact === false) {
      return;
    }

    const hasUserAlreadyReacted = submittedReactionsIds != null && submittedReactionsIds.length > 0;
    if (hasUserAlreadyReacted === true) {
      return;
    }

    setStoryState(storyManager, { type: 'reacting' });
  }, [storyState.type, canReact, submittedReactionsIds, storyManager]);

  useEffect(() => {
    onChangeStoryState(storyState);
  }, [onChangeStoryState, storyState]);

  // Open the reaction dialog using the search params
  const openedReactionDialog = useRef(false);
  useEffect(() => {
    const reaction = searchParams.get('reaction');
    if (reaction && !openedReactionDialog.current) {
      openedReactionDialog.current = true;
      setStoryState(storyManager, { type: 'reacting' });
    }
  }, [searchParams, storyManager, storyViewerAnalyticsService]);

  // Used to animate the exiting
  let exitingStoryId = null;
  if (animationDir === 'rtl') {
    exitingStoryId = previousStoryId;
  } else if (animationDir === 'ltr') {
    exitingStoryId = nextStoryId;
  }

  const handleViewHighlightReel = useCallback(() => {
    // should close story view dialog if it was open from a highlight reel
    if (storiesType === 'highlight-reel-standalone') {
      onClose();
      return;
    }

    window.open(getHighlightReelPath(storyId));
  }, [onClose, storiesType, storyId]);

  const shouldAnimate = activeStory || exitingStoryId === storyId;

  // Controls visibility
  const hoveringTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const hideControlsTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const handleMouseOver = useCallback(
    (hovering: boolean) => {
      if (isMobileViewport()) {
        return;
      }

      if (hoveringTimeoutRef.current !== null) {
        clearTimeout(hoveringTimeoutRef.current);
        hoveringTimeoutRef.current = null;
      }

      // We should only auto hide the controls in the fullscreen mode.
      if (isFullScreen(fullscreenStore)) {
        hoveringTimeoutRef.current = setTimeout(() => {
          const state = getStoryState(storyManager);
          if (state.type !== 'view') {
            // The user can open the writing assistant or edit the summary while viewing the story.
            // In that case, we should not hide the controls (or change the state)
            return;
          }
          setStoryState(storyManager, {
            type: 'view',
            controls: 'hidden',
            assistant: 'hidden',
          });
          hoveringTimeoutRef.current = null;
        }, 5000);

        if (hovering == false) {
          return;
        }
      }

      // When the user is hovering, we should show the controls immediately.
      // We use this timeout to avoid rendering issues when hovering over different components
      // of the story player.
      if (hideControlsTimeoutRef.current != null) {
        clearTimeout(hideControlsTimeoutRef.current);
        hideControlsTimeoutRef.current = null;
      }
      hideControlsTimeoutRef.current = setTimeout(() => {
        const state = getStoryState(storyManager);
        const newControlsVisibility = hovering ? 'visible' : 'hidden';
        if (state.type !== 'view' || state.controls === newControlsVisibility) {
          return;
        }

        setStoryState(storyManager, {
          type: 'view',
          controls: newControlsVisibility,
          assistant: state.assistant,
        });
      }, 10);
    },
    [fullscreenStore, storyManager],
  );

  useEffect(() => {
    // Auto hide the player controls after playing the video for 5 seconds.
    // We should only do this on mobile.
    if (isMobileViewport() == false || isPlaying == false) {
      return;
    }

    const hideControls = () => {
      const state = getStoryState(storyManager);
      if (state.type !== 'view') {
        // The user can open the writing assistant or edit the summary while viewing the story.
        // In that case, we should not hide the controls (or change the state)
        return;
      }
      setStoryState(storyManager, {
        type: 'view',
        controls: 'hidden',
        assistant: 'hidden',
      });
    };

    let timeoutId: ReturnType<typeof setTimeout> | null = setTimeout(hideControls, 5000);

    const unsubscribe = observeStoryState(storyManager, (state, prevState) => {
      if (state.type !== 'view') {
        return;
      }

      // Clear the timeout when the controls are hidden
      if (state.controls === 'hidden') {
        if (timeoutId !== null) {
          clearTimeout(timeoutId);
          timeoutId = null;
        }
        return;
      }

      // Set the timeout again when the controls are visible
      if (state.controls === 'visible' && (prevState.type !== 'view' || prevState.controls === 'hidden')) {
        timeoutId = setTimeout(hideControls, 5000);
      }
    });

    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
      unsubscribe();
    };
  }, [isPlaying, storyManager]);

  // Trigger the analytics when the story becomes inactive or on unmount.
  // videoStartTime.current will be null if the analytics have already been fired.
  useEffect(() => {
    const checkAndTriggerStoryWatchedEvent = (unmounting: boolean) => {
      // We pre-render multiple story view containers in the carousel.
      // The visible container has `activeStory` set to true.
      // If the active story is being mounted, we don't need
      // to trigger a watch event. We also don't need to trigger a watch event when is story
      // becomes inactive (or is unmounted) if the video was not playing to begin  with (videoStartTime == null)
      if (videoStartTime.current == null || (activeStory == true && unmounting == false)) {
        return;
      }

      videoWatchedTotalDuration.current += videoCurrentTime.current - videoStartTime.current;

      // Make sure the duration is rounded to the nearest 1/100
      const duration = Math.round(videoWatchedTotalDuration.current * 100) / 100;
      storyViewerAnalyticsService.onStoryWatched(duration, 'original');

      videoStartTime.current = null;
      videoWatchedTotalDuration.current = 0;
      videoCurrentTime.current = 0;
    };

    checkAndTriggerStoryWatchedEvent(false);

    return () => {
      checkAndTriggerStoryWatchedEvent(true);
    };
  }, [activeStory, storyViewerAnalyticsService]);

  return (
    <StoryView animationDir={shouldAnimate ? animationDir : null} activeStory={activeStory}>
      <PlayerContext
        title={title}
        src={src}
        autoPlay={activeStory}
        paused={!activeStory}
        playsInline
        crossOrigin
        // This will prevent vidstack from focusing on some other control
        // when the player enters the idle state
        controlsDelay={24 * 60 * 60 * 1000}
        onPlay={handlePlay}
        onPause={handlePause}
        onEnded={handleEnded}
        onTimeUpdate={handleTimeUpdate}
        onError={(e) => {
          logger.error('VIDEO_PLAYER.ERROR', e, e.error);
        }}
        onHlsError={(e) => {
          logger.error('VIDEO_PLAYER.HLS.ERROR', e, e.error);
        }}
      >
        <StoryPlayerContainer
          storyId={storyId}
          storiesManager={storiesManager}
          storyManager={storyManager}
          summaryForm={summaryForm}
          titleTextEditor={titleTextEditor}
          summaryTextEditor={summaryTextEditor}
          regenerateForm={regenerateForm}
          promptText={promptQuestion?.text ?? ''}
          onMouseOver={handleMouseOver}
          onViewHighlightReel={reelAlternative != null ? handleViewHighlightReel : undefined}
        />

        {activeStory && <StoryShareDialogContainer storiesManager={storiesManager} storyManager={storyManager} />}

        {activeStory && (user == null || project != null) && canViewProject == true && (
          <StoryReactionDialogContainer
            open={storyState.type === 'reacting'}
            recordingId={recordingId}
            page={storiesManager.type}
            onClose={() => setStoryState(storyManager, { type: 'view', controls: 'visible', assistant: 'hidden' })}
          />
        )}

        {activeStory && (user == null || project != null) && canViewProject == false && (
          <StoryAnonymousReactionDialogContainer
            open={storyState.type === 'reacting'}
            recordingId={recordingId}
            initialSentiment={storyState.type === 'reacting' ? storyState.initialSentiment ?? null : null}
            page={storiesManager.type}
            onClose={() => setStoryState(storyManager, { type: 'view', controls: 'visible', assistant: 'hidden' })}
          />
        )}
      </PlayerContext>
    </StoryView>
  );
}
