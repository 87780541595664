import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RMConfirmationModal } from '@/components/RMConfirmationModal';
import { useIsMobileOrTabletViewport } from '@/hooks/useIsMobileViewport';
import { getSigninPath } from '@/modules/routing';
import { useServices } from '@/Services';
import { useUser } from '@/services/api/auth/auth.service.hook';

import { LearnMoreDialog } from '../components/LearnMoreDialog/LearnMoreDialog';
import { StoryDialog } from '../components/StoryDialog/StoryDialog';
import {
  hasNextStory,
  hasPreviousStory,
  nextStory,
  previousStory,
  setActiveStoryId,
  StoriesManager,
  useActiveStoryId,
  useStoriesIds,
} from '../states/stories.manager';
import { StoryState } from '../states/story.manager';

import { StoryPlaceholderContainer } from './StoryPlaceholder.container';

export interface StoryViewContainerProps {
  storiesManager: StoriesManager;
  showClose?: boolean;
}

export function StoryDialogContainer({ storiesManager, showClose = true }: StoryViewContainerProps) {
  const { storyViewerAnalyticsService, redirectService } = useServices();
  const navigate = useNavigate();

  const user = useUser();
  const storiesIds = useStoriesIds(storiesManager);
  const activeStoryId = useActiveStoryId(storiesManager);

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [animationDir, setAnimationDir] = useState<'ltr' | 'rtl' | null>(null);
  const [canChangeStory, setCanChangeStory] = useState(false);
  const [confirmCloseOpen, setConfirmCloseOpen] = useState(false);

  // We need to hide the close button when editing the story on mobile.
  const isMobileOrTablet = useIsMobileOrTabletViewport();
  const [isEditing, setIsEditing] = useState(false);

  const open = activeStoryId !== null;

  const handleClose = useCallback(() => {
    if (!isFormDirty || confirmCloseOpen) {
      setActiveStoryId(storiesManager, null);
      setConfirmCloseOpen(false);
    } else {
      setConfirmCloseOpen(true);
    }
  }, [storiesManager, isFormDirty, confirmCloseOpen]);

  const handleFormDirtyChange = useCallback((isDirty: boolean) => {
    setIsFormDirty(isDirty);
  }, []);

  const handleChangeStoryState = useCallback((state: StoryState) => {
    setIsEditing(state.type === 'editing');
    setCanChangeStory(state.type === 'view');
  }, []);

  const handleNext = useCallback(() => {
    setAnimationDir('rtl');
    nextStory(storiesManager);
  }, [storiesManager]);

  const handlePrevious = useCallback(() => {
    setAnimationDir('ltr');
    previousStory(storiesManager);
  }, [storiesManager]);

  const handleSignIn = useCallback(async () => {
    await redirectService.registerRedirect('signed-in', window.location.pathname + window.location.search);
    navigate(getSigninPath());
  }, [redirectService, navigate]);

  useEffect(() => {
    if (open) {
      storyViewerAnalyticsService.onStoryArrived();
    } else {
      setAnimationDir(null);
    }
  }, [open, storyViewerAnalyticsService]);

  useEffect(() => {
    setIsFormDirty(false);
  }, [activeStoryId]);

  const canChangeStoryToNext = canChangeStory && hasNextStory(storiesManager);
  const canChangeStoryToPrevious = canChangeStory && hasPreviousStory(storiesManager);

  // Exit intent dialog
  const [exitIntentDialogOpen, setExitIntentDialogOpen] = useState(false);
  const [exitIntentDialogShown, setExitIntentDialogShown] = useState(false);

  const handleLearnMore = useCallback(() => {
    window.open('https://remento.co', '_blank');
  }, []);

  useEffect(() => {
    // If the user is undefined, that means that the user is still loading
    // and we should NOT show the exit intent dialog.
    if (user === undefined || user !== null || exitIntentDialogShown) {
      return;
    }

    const mouseLeaveListener = (event: MouseEvent) => {
      if (event.clientY < 0) {
        setExitIntentDialogOpen(true);
        setExitIntentDialogShown(true);
      }
    };

    document.body.addEventListener('mouseleave', mouseLeaveListener);
    return () => {
      document.body.removeEventListener('mouseleave', mouseLeaveListener);
    };
  }, [exitIntentDialogShown, user]);

  return (
    <>
      <StoryDialog
        open={open}
        showSignIn={user === null}
        showClose={showClose && (isEditing == false || isMobileOrTablet == false)}
        onNext={canChangeStoryToNext ? handleNext : null}
        onPrevious={canChangeStoryToPrevious ? handlePrevious : null}
        onSignIn={handleSignIn}
        onClose={handleClose}
      >
        {storiesIds.map((id) => (
          <StoryPlaceholderContainer
            key={id}
            storyId={id}
            storiesManager={storiesManager}
            animationDir={animationDir}
            onFormDirtyChange={handleFormDirtyChange}
            onChangeStoryState={handleChangeStoryState}
            onClose={handleClose}
          />
        ))}
      </StoryDialog>
      <RMConfirmationModal
        open={confirmCloseOpen}
        title="Discard changes?"
        message="You have unsaved changes on this page. Would you like to discard them?"
        confirmLabel="Discard changes"
        type="danger"
        onConfirm={handleClose}
        onCancel={() => setConfirmCloseOpen(false)}
      />

      <LearnMoreDialog
        open={exitIntentDialogOpen}
        onLearnMore={handleLearnMore}
        onClose={() => setExitIntentDialogOpen(false)}
      />
    </>
  );
}
